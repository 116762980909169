import React from "react";
import logo from "../assets/logo.png";
import { textString } from "../assets/TextStrings";
import batteryImage from "../assets/battery.png";
import oilImage from "../assets/oil.png";
import tyreImage from "../assets/tyre.png";
import { FiTrash } from "react-icons/fi";
import CartPageImage from "../assets/cartpage.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { setCartItems } from "../store/authSlice";
function CartPage() {
  const navigate = useNavigate();
  const isArabic = false;
  const { cartItems } = useSelector((state) => state.auth);
  const { filtersData, oilsData, tireData, batteryData } = useSelector(
    (state) => state.project
  );
  const filteredDataFun = (id, referance) => {
    const finalizingDataType =
      referance === "Filters"
        ? filtersData
        : referance === "Tyres"
        ? tireData
        : referance === "btteries"
        ? batteryData
        : referance === "Oils"
        ? oilsData
        : [];
    const finalData = finalizingDataType?.find((dat) => dat.id === id);
    return finalData;
  };

  const dispatch = useDispatch();
  const removeToCartsItem = async (indexId) => {
    const newCartItems = cartItems?.filter((dat, ind) => ind !== indexId);
    await AsyncStorage.setItem(
      "ac_Zurex_client_cart",
      JSON.stringify(newCartItems)
    );
    dispatch(setCartItems({ cartItems: newCartItems }));
    toast.success("Product added to Cart");
  };
  function calculatePrice() {
    let price = 0;
    cartItems?.map((dat, index) => {
      const data = filteredDataFun(dat.id, dat.referance);
      const newPriceData =
        parseFloat(data.originalPrice) * parseInt(dat.quantity);
      price = price + newPriceData;
      return null;
    });
    return price;
  }
  return (
    <div className="container mt-4 cartPage">
      {cartItems && cartItems?.length > 0 ? (
        <>
          <img src={logo} className="topLogoPagediv" alt="logo" />
          <h1 className="pageHeading">{textString.cartTxt}</h1>
          <div className="row mt-2 mb-4">
            {cartItems?.map((dat, index) => {
              const data = filteredDataFun(dat.id, dat.referance);
              return (
                <div key={index} className="col-12 col-md-6 mb-4">
                  <div className="cartCardContainer d-flex align-items-center justify-content-center flex-column flex-md-row">
                    <img
                      src={
                        dat.referance === "Filters" || dat.referance === "Oils"
                          ? oilImage
                          : dat.referance === "btteries"
                          ? batteryImage
                          : dat.referance === "Tyres"
                          ? tyreImage
                          : logo
                      }
                      alt="product"
                    />
                    <div className="otherInfoContainer">
                      <p>
                        {!isArabic
                          ? data?.productNameEng
                          : data?.productNameArab}
                      </p>
                      <div className="lowerSideContainer">
                        <p>
                          {data?.originalPrice}*{dat?.quantity} ={" "}
                          {data?.originalPrice * dat?.quantity}{" "}
                          {textString.currencyTxt}
                        </p>
                        <button onClick={() => removeToCartsItem(index)}>
                          <FiTrash />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="btnContainersBotm">
            <div className="row mt-2 w-100 d-flex align-items-center justify-content-center">
              <div className="col-12 col-md-6 mb-4 d-flex align-items-center justify-content-center">
                <button className="firstBtn" style={{ cursor: "none" }}>
                  {textString.totalTxt} : {calculatePrice()}{" "}
                  {textString.currencyTxt}
                </button>
              </div>
              <div className="col-12 col-md-6 mb-4 d-flex align-items-center justify-content-center">
                <button onClick={() => navigate("/pay")} className="lastBtn">
                  {textString.completOrder}
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <h1 className="pageHeading">{textString.cartTxt}</h1>
          <div className="emptyCart">
            <img src={CartPageImage} className="cartImage" alt="Empty Cart" />
            <p>{textString.emptyCrtTxt}</p>
            <button onClick={() => navigate("/")} className="lastBtn">
              {textString.shopNowTxt}
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default CartPage;
