import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import banner1 from "../assets/banner1.png";
import banner2 from "../assets/banner2.png";
import banner3 from "../assets/banner3.png";
import banner4 from "../assets/banner4.png";
import Slider from "react-slick";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import { textString } from "../assets/TextStrings";
import batteryImage from "../assets/battery.png";
import oilImage from "../assets/oil.png";
import tyreImage from "../assets/tyre.png";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import logo from "../assets/logo.png";
import closeIcon from "../assets/ShieldFail.png";
import appStore1 from "../assets/appstore (1).png";
import appStore2 from "../assets/appstore (2).png";
import "../index.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurentOrderProductData,
  setOrderProcessName,
} from "../store/orderProcessSlice";
function HomePage() {
  const dispatch = useDispatch();
  const { filtersData, oilsData, tireData, batteryData, supportServicesData } =
    useSelector((state) => state.project);
  const [slctedBtn, setslctedBtn] = useState(0);
  const [selctedService, setselctedService] = useState(0);
  const [show, setshow] = useState(false);
  const handleClose = () => {
    setshow(!show);
  };
  const navigate = useNavigate();

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  const isArabic = false;
  const resultArray =
    slctedBtn === 1
      ? [...filtersData, ...oilsData]
      : slctedBtn === 2
      ? batteryData
      : slctedBtn === 3
      ? tireData
      : [];
  const supportNextBtn = async () => {
    dispatch(
      setCurentOrderProductData({
        curentOrderProductData: supportServicesData[selctedService - 1],
      })
    );
    dispatch(setOrderProcessName({ orderProcessName: "support" }));
    navigate("/pay");
  };
  return (
    <div className="container hompage">
      <div
        onClick={() => navigate("/enterData")}
        className="btn w-100 homeBtnMain mt-4"
      >
        {textString.enterYourCarDataTxt}
      </div>
      <div onClick={handleClose} className="btn w-100 homeBtnSec mt-4">
        {textString.downloadAppTxt}
      </div>
      <Carousel
        variant="dark"
        className="my-4 crouselCustomClass"
        fade
        controls={false}
        interval={4500}
      >
        <Carousel.Item>
          <img
            style={{ width: "100%", objectFit: "contain" }}
            src={banner1}
            alt="banner1"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ width: "100%", objectFit: "contain" }}
            src={banner2}
            alt="banner2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ width: "100%", objectFit: "contain" }}
            src={banner3}
            alt="banner3"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ width: "100%", objectFit: "contain" }}
            src={banner4}
            alt="banner4"
          />
        </Carousel.Item>
      </Carousel>
      <p className="headingPara custom25MarginBottom">
        {textString.homeNavTxt}
        {" > "}
        <span className="smallNav">
          {slctedBtn === 0
            ? textString.productNavTxt
            : slctedBtn === 1
            ? textString.oilFilterTxt
            : slctedBtn === 2
            ? textString.batteryTxt
            : slctedBtn === 3
            ? textString.tireTxt
            : slctedBtn === 4
            ? textString.supportTxt
            : " "}
        </span>
      </p>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-3 mb-5">
          <div
            onClick={() => setslctedBtn(1)}
            className={
              slctedBtn === 1 ? "productBtnSelctr activeBg" : "productBtnSelctr"
            }
          >
            <div
              className={slctedBtn === 1 ? "upperHead activeBg" : "upperHead"}
            />
            <h3>{textString.oilFilterTxt}</h3>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-3 mb-5">
          <div
            onClick={() => setslctedBtn(2)}
            className={
              slctedBtn === 2 ? "productBtnSelctr activeBg" : "productBtnSelctr"
            }
          >
            <div
              className={slctedBtn === 2 ? "upperHead activeBg" : "upperHead"}
            />
            <h3>{textString.batteryTxt}</h3>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-3 mb-5">
          <div
            onClick={() => setslctedBtn(3)}
            className={
              slctedBtn === 3 ? "productBtnSelctr activeBg" : "productBtnSelctr"
            }
          >
            <div
              className={slctedBtn === 3 ? "upperHead activeBg" : "upperHead"}
            />
            <h3>{textString.tireTxt}</h3>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-3 mb-5">
          <div
            onClick={() => setslctedBtn(4)}
            className={
              slctedBtn === 4 ? "productBtnSelctr activeBg" : "productBtnSelctr"
            }
          >
            <div
              className={slctedBtn === 4 ? "upperHead activeBg" : "upperHead"}
            />
            <h3>{textString.supportTxt}</h3>
            <p>{textString.gurenteeTxt}</p>
          </div>
        </div>
      </div>
      <div className="row">
        {slctedBtn > 0 &&
          slctedBtn < 4 &&
          resultArray?.map((dat, index) => (
            <div key={index} className="col-12 col-md-6 col-lg-3 mb-4">
              <div
                onClick={() =>
                  navigate(
                    `/productInfo/${dat?.productNameEng
                      ?.replace(/[^a-zA-Z ]/g, "")
                      .replace(/ /g, "_")}_@_${dat.referance}_@_${dat.id}`
                  )
                }
                className="cartCardContainerNew d-flex align-items-center justify-content-center flex-column"
              >
                <img
                  src={
                    slctedBtn === 1
                      ? oilImage
                      : slctedBtn === 2
                      ? batteryImage
                      : slctedBtn === 3
                      ? tyreImage
                      : logo
                  }
                  alt="product"
                />
                <div className="otherInfoContainer">
                  <p className="fs-3">
                    {isArabic ? dat?.productNameArab : dat?.productNameEng}
                  </p>
                  <div className="lowerSideContainer">
                    <p className="lowerPara">
                      {dat?.originalPrice} {textString.currencyTxt}
                    </p>
                  </div>
                  <button className="addToCartBtn">
                    <AiOutlineShoppingCart /> {textString.addToCartTxt}
                  </button>
                </div>
              </div>
            </div>
          ))}
        {slctedBtn === 4 && (
          <>
            <div className="SupportContainer w-100">
              <div className="row w-100">
                {supportServicesData?.map((dat, index) => {
                  const titleOnly = dat?.products?.map((dac) =>
                    isArabic ? dac.productNameArab : dac.productNameEng
                  );
                  const suportTitle = titleOnly?.join(" , ");
                  return (
                    <div className="col-12 col-md-6 col-lg-3 mb-4">
                      <div
                        onClick={() => setselctedService(index + 1)}
                        className="cartCardContainerNew1 h-auto d-flex align-items-center justify-content-center flex-column"
                        style={{
                          borderColor:
                            selctedService === index + 1
                              ? "#8c1726"
                              : "#003978",
                          minHeight: "270px",
                        }}
                      >
                        <div className="imagContainer">
                          <img
                            src={require("../assets/5138237.jpg")}
                            alt="product"
                          />
                        </div>
                        <div className="otherInfoContainer">
                          <p
                            className="fs-4"
                            style={{
                              color:
                                selctedService === index + 1
                                  ? "#8c1726"
                                  : "#000",
                              fontWeight:
                                selctedService === index + 1 ? "600" : "normal",
                            }}
                          >
                            {suportTitle}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {selctedService !== 0 ? (
              <div className="nextBtnDiv">
                <button onClick={supportNextBtn}>
                  {textString.nextbtnTxt}
                </button>
              </div>
            ) : null}
          </>
        )}
      </div>
      <p className="headingPara custom25MarginBottom">
        {textString.customerReviewTxt}
      </p>
      <div className="w-100 mb-4">
        <Slider {...settings}>
          {Array(8)
            .fill(null)
            .map((index) => (
              <div key={index} className="reviewCard">
                <div className="userAvtar">
                  <img
                    src="https://images.unsplash.com/photo-1682917265562-139c5aa7070c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8MTB8fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60"
                    alt="user"
                  />
                </div>
                <h3>Ahmad Al-hosari</h3>
                <p>{textString.userReviewTxt}</p>
              </div>
            ))}
        </Slider>
      </div>
      <div style={{ marginBottom: "5rem" }} />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <button onClick={handleClose} className="custbutton">
            <img src={closeIcon} alt="close" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="modalBtnContainers">
            <img src={logo} className="topLogoPagediv" alt="logo" />
            <p
              style={{
                fontSize: "1.813rem",
                lineHeight: "2.5rem",
                fontWeight: "bold",
                textAlign: "center",
                color: "#003978",
              }}
              className="paraModalWlal"
            >
              {textString.downloadAppTxtNew}
            </p>
            <div className="borderedDiv">
              <img src={appStore1} alt="appstore" />
              <img src={appStore2} alt="playstore" />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default HomePage;
