import React, { useEffect } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import CartPage from "../Pages/CartPage";
import ErrorPage from "../Pages/ErrorPage";
import HomePage from "../Pages/HomePage";
import TipDescriptionPage from "../Pages/TipDescriptionPage";
import TipPage from "../Pages/TipPage";
import ProductDescriptionPage from "../Pages/ProductDescriptionPage";
import AboutPage from "../Pages/AboutPage";
import FavoritePage from "../Pages/FavoritePage";
import HelpPage from "../Pages/HelpPage";
import LoginPage from "../Pages/LoginPage";
import ContactPage from "../Pages/ContactPage";
import WalletPage from "../Pages/WalletPage";
import PaymentPage from "../Pages/PaymentPage";
import PaySuccessPage from "../Pages/PaySuccessPage";
import EnterCarDataPage from "../Pages/EnterCarDataPage";
import AddressPage from "../Pages/AddressPage";
import { useSelector } from "react-redux";

function FileRoutesNav() {
  const { pathname } = useLocation();
  const { isAuth } = useSelector((state) => state.auth);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Routes>
      <Route path="/tips" element={<TipPage />} />
      <Route path="/enterData" element={<EnterCarDataPage />} />
      <Route path="/productInfo/:id" element={<ProductDescriptionPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/favorite" element={<FavoritePage />} />
      <Route path="/help" element={<HelpPage />} />
      <Route
        path="/login"
        element={isAuth ? <Navigate to={"/wallet"} /> : <LoginPage />}
      />
      <Route path="/contact" element={<ContactPage />} />
      <Route
        path="/wallet"
        element={isAuth ? <WalletPage /> : <Navigate to={"/login"} />}
      />
      <Route
        path="/shippingInfo"
        element={isAuth ? <AddressPage /> : <Navigate to={"/login"} />}
      />
      <Route
        path="/pay"
        element={isAuth ? <PaymentPage /> : <Navigate to={"/login"} />}
      />
      <Route path="/tip/:id" element={<TipDescriptionPage />} />
      <Route path="/cart" element={<CartPage />} />
      <Route path="/paymentSuccess" element={<PaySuccessPage />} />
      <Route path="/" element={<HomePage />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default FileRoutesNav;
