import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import batteryImage from "../assets/battery.png";
import oilImage from "../assets/oil.png";
import tyreImage from "../assets/tyre.png";
import Carousel from "react-bootstrap/Carousel";
import { textString } from "../assets/TextStrings";
import { FaStar } from "react-icons/fa";

import {
  AiOutlineInstagram,
  //AiOutlineStar,
  AiOutlinePlus,
  AiOutlineMinus,
  AiOutlineShoppingCart,
  AiOutlineHeart,
} from "react-icons/ai";
import { FaSnapchat, FaFacebookF, FaTwitter } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { setCartItems, setFavoriteItems } from "../store/authSlice";

function ProductDescriptionPage() {
  const dispatch = useDispatch();
  const { filtersData, oilsData, tireData, batteryData } = useSelector(
    (state) => state.project
  );
  const { cartItems, favoriteItems } = useSelector((state) => state.auth);
  const [productData, setproductData] = useState(null);
  const { id } = useParams();
  const [itemTotal, setitemTotal] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    const filteredDataFun = () => {
      const responce = id.split("_@_");
      const productId = responce[2];
      const referance = responce[1];
      const productName = responce[0];
      console.log(productName);
      const finalizingDataType =
        referance === "Filters"
          ? filtersData
          : referance === "Tyres"
          ? tireData
          : referance === "btteries"
          ? batteryData
          : referance === "Oils"
          ? oilsData
          : [];
      const finalData = finalizingDataType?.find((dat) => dat.id === productId);
      setproductData(finalData);
    };
    filteredDataFun();
  }, [filtersData, oilsData, tireData, batteryData, id]);

  const filterData = []?.filter((dat) => dat?.title?.replace(/ /g, "_") === id);
  const result = filterData?.length > 0 ? filterData[0] : {};
  const imgArray = [
    { imglInk: oilImage },
    { imglInk: batteryImage },
    { imglInk: oilImage },
    { imglInk: tyreImage },
    { imglInk: batteryImage },
  ];
  const isArabic = false;
  const addToFavoriteFun = async () => {
    const newFavoriteItems =
      favoriteItems?.length > 0
        ? [
            ...favoriteItems,
            {
              referance: productData?.referance,
              quantity: 1,
              id: productData?.id,
            },
          ]
        : [
            {
              referance: productData?.referance,
              quantity: 1,
              id: productData?.id,
            },
          ];
    dispatch(setFavoriteItems({ favoriteItems: newFavoriteItems }));
    await AsyncStorage.setItem(
      "ac_Zurex_client_cart",
      JSON.stringify(newFavoriteItems)
    );
    toast.success("Product added to favorites");
  };
  const addToCartsItem = async () => {
    const newCartItems =
      cartItems?.length > 0
        ? [
            ...cartItems,
            {
              referance: productData?.referance,
              quantity: itemTotal,
              id: productData?.id,
            },
          ]
        : [
            {
              referance: productData?.referance,
              quantity: itemTotal,
              id: productData?.id,
            },
          ];
    await AsyncStorage.setItem(
      "ac_Zurex_client_favorite",
      JSON.stringify(newCartItems)
    );
    dispatch(setCartItems({ cartItems: newCartItems }));

    navigate("/cart");
    toast.success("Product added to Cart");
    console.log("webengage start");

    window.webengage.track("cart product", {
      product: "tyre",
    });
    console.log("webengage end");
  };
  return (
    <div className="container my-5 productDescriptionPage">
      <div className="row">
        <div className="col-12 col-md-6 h-100 d-flex align-items-center justify-content-between flex-column">
          <Carousel
            variant="dark"
            className="my-4 crouselCustomClassNew"
            fade
            controls={false}
            interval={4500}
          >
            {imgArray?.map((dat, index) => (
              <Carousel.Item>
                <div key={index} className="crouselImg">
                  <img src={dat?.imglInk} alt="productimg" />
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
          <div className="ProductSharingDiv mb-4">
            <p>{textString.productSharinTxt}</p>
            <div className="btnContainerSocial">
              <button>
                <FaFacebookF />
              </button>
              <button>
                <FaTwitter />
              </button>
              <button>
                <AiOutlineInstagram />
              </button>
              <button>
                <FaSnapchat />
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="productHeaderDiv flex-column flex-md-row">
            <div className="infoContainer">
              <h5>
                {!isArabic
                  ? productData?.productNameEng
                  : productData?.productNameArab}
              </h5>
              <p>
                {textString.categoryBtnTxt}::
                {productData?.referance?.toLowerCase()?.includes("oils") ||
                productData?.referance?.toLowerCase()?.includes("filters")
                  ? textString.oilFilterTxt
                  : productData?.referance?.toLowerCase()?.includes("btteries")
                  ? textString.batteryTxt
                  : productData?.referance?.toLowerCase()?.includes("tyres")
                  ? textString.tireTxt
                  : ""}
              </p>
              <div className="ratingDiv">
                {Array(5)
                  .fill()
                  .map((index) => (
                    <FaStar />
                  ))}
              </div>
            </div>
            <div className="priceContainer align-items-start align-items-md-end">
              {productData?.originalPrice} {textString.currencyTxt}
            </div>
          </div>
          <div className="productMoreInfoDiv my-5">
            <p>
              {productData?.productDescArab && productData?.productDescEng
                ? isArabic
                  ? productData?.productDescArab
                  : productData?.productDescEng
                : ""}
            </p>
            <ul>
              {productData?.productDiemensions ? (
                productData?.productDiemensions?.map((dat, index) => (
                  <li key={index}>
                    <div className="w-100 d-flex align-items-center justify-content-between flex-row">
                      <div>{isArabic ? dat?.nameArab : dat?.nameEng}/</div>
                      <div>{dat?.value ? dat?.value : productData?.id}</div>
                    </div>
                  </li>
                ))
              ) : (
                <li>
                  <div className="w-100 d-flex align-items-center justify-content-between flex-row">
                    <div> {textString.itemNumTxt}/</div>
                    <div>{productData?.id}</div>
                  </div>
                </li>
              )}
            </ul>
            <p className="lowerRedPara">
              {textString.orderYourTxt}
              {result?.title?.toLowerCase()?.includes("oil")
                ? textString.oilFilterTxt
                : result?.title?.toLowerCase()?.includes("battery")
                ? textString.batteryTxt
                : result?.title?.toLowerCase()?.includes("tyre")
                ? textString.tireTxt
                : ""}
              {textString.orderYourTxt2}
            </p>
          </div>
          <div className="row mb-4">
            <div className="col-xs-12 col-md-8 mb-2">
              <div className="d-flex align-items-center justify-content-center flex-row inlineBtnRow">
                <button
                  onClick={() => {
                    if (itemTotal > 1) {
                      setitemTotal(itemTotal - 1);
                    } else {
                      alert("Least quantity reached");
                    }
                  }}
                >
                  <AiOutlineMinus />
                </button>
                {itemTotal}
                <button onClick={() => setitemTotal(itemTotal + 1)}>
                  <AiOutlinePlus />
                </button>
              </div>
            </div>
            <div className="col-xs-12 col-md-4 mb-2">
              <div className="PriceCalct">
                {productData?.originalPrice * itemTotal}{" "}
                {textString.currencyTxt}
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 col-md-6 mb-2 d-flex align-items-center justify-content-center">
              <button onClick={addToCartsItem} className="button firstBtn">
                <AiOutlineShoppingCart /> {textString.addToCartTxt}
              </button>
            </div>
            <div className="col-12 col-md-6 mb-2 d-flex align-items-center justify-content-center">
              <button onClick={addToFavoriteFun} className="button lastBtn">
                <AiOutlineHeart /> {textString.addFavriteTxt}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDescriptionPage;
