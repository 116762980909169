import React from "react";
import balanceImg from "../assets/balance.png";
import orderImg from "../assets/order.png";
import { textString } from "../assets/TextStrings";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import profileImage from "../assets/face.png";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { setAuth } from "../store/authSlice";
function WalletPage() {
  const { isAuth } = useSelector((state) => state.auth);
  const { mySupportServicesData } = useSelector((state) => state.project);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  if (!isAuth) {
    navigate("/login");
  }
  const logoutFunc = async () => {
    await AsyncStorage.removeItem("ac_zurex_web_client");
    dispatch(setAuth({ isAuth: null }));
  };
  return (
    <div
      className="container walletPage"
      style={{ marginBottom: "7rem", marginTop: "7rem" }}
    >
      <div className="w-100 mb-4 d-flex align-items-center justify-content-center flex-column">
        <div className="useraImageContaner">
          <img src={profileImage} alt="user" />
        </div>
        <div className="d-flex align-items-center justify-content-center flex-row"></div>
        <p className="usernamePara">
          {isAuth?.name.lenght > 0
            ? isAuth?.name === "client default username"
              ? isAuth.phoneNumber
              : isAuth?.name
            : isAuth.phoneNumber}
        </p>
        <button onClick={logoutFunc} className="customLogoutBtn">
          Logout
        </button>
      </div>
      <div className="row mt-4 mb-4">
        <div className="col-12 col-md-6 mb-3">
          <div className="walletCardDiv">
            <img src={balanceImg} alt="balance" />
            <p>{textString.balanceTxt}</p>
            <p className="priceTxt">
              {isAuth?.balance ? isAuth?.balance : 0} {textString.currencyTxt}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-6 mb-3">
          <div className="walletCardDiv">
            <img src={orderImg} alt="order" />
            <p>{textString.supportServiceTxt}</p>
            <p className="priceTxt">
              {mySupportServicesData?.length
                ? mySupportServicesData?.length
                : 0}{" "}
              {textString.orderTxt}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WalletPage;
