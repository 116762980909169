import React, { useState } from "react";
import logo from "../assets/logo.png";
import { textString } from "../assets/TextStrings";
import payment2 from "../assets/payment2.png";
import payment3 from "../assets/payment3.png";
import payment4 from "../assets/payment4.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function PaymentPage() {
  const [selectInput, setselectInput] = useState(0);
  const navigate = useNavigate();
  const { isAuth } = useSelector((state) => state.auth);

  if (!isAuth) {
    navigate("/login");
  }
  return (
    <div className="container paymentPage my-4">
      <img src={logo} className="topLogoPagediv" alt="logo" />
      <h1 className="pageHeading">{textString.choosePayMehodTxt}</h1>
      <div className="row my-4 justify-content-center">
        <div className="col-12 col-md-6 col-lg-3">
          <div
            onClick={() => setselectInput(2)}
            className="paymentCardImagContainer"
            style={{
              border: `1px solid ${
                selectInput === 2 ? "#003978" : "transparent"
              }`,
            }}
          >
            <img src={payment2} alt="payment2" />
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-3">
          <div
            onClick={() => setselectInput(3)}
            className="paymentCardImagContainer"
            style={{
              border: `1px solid ${
                selectInput === 3 ? "#003978" : "transparent"
              }`,
            }}
          >
            <img src={payment3} alt="payment3" />
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-3">
          <div
            onClick={() => setselectInput(4)}
            className="paymentCardImagContainer"
            style={{
              border: `1px solid ${
                selectInput === 4 ? "#003978" : "transparent"
              }`,
            }}
          >
            <img src={payment4} alt="payment4" />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <button
          className="paynextBtn"
          onClick={() => navigate("/shippingInfo")}
        >
          {textString.nextbtnTxt}
        </button>
      </div>
      <div style={{ marginBottom: "4rem" }} />
    </div>
  );
}

export default PaymentPage;
