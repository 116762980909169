import "./TopBar.scss";
import React from "react";
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import logoimg from "../../assets/navlogo.png";
import { BsTelephone } from "react-icons/bs";
import {
  AiOutlineShoppingCart,
  AiOutlineHeart,
  AiOutlineInstagram,
} from "react-icons/ai";
import { FaSnapchat } from "react-icons/fa";
import { textString } from "../../assets/TextStrings";
import { useSelector } from "react-redux";

function TopBar() {
  const { isAuth } = useSelector((state) => state.auth);

  const CustNavs = () => {
    return (
      <>
        <Nav.Link eventKey="1" className=" text-center my-auto mx-1">
          <NavLink
            className={({ isActive }) =>
              !isActive ? "inActiveColor" : "activeColor"
            }
            to="/"
          >
            {textString.homeNavTxt}
          </NavLink>
        </Nav.Link>
        <Nav.Link eventKey="43" className=" text-center my-auto mx-1">
          <NavLink
            className={({ isActive }) =>
              !isActive ? "inActiveColor" : "activeColor"
            }
            to="/about"
          >
            {textString.aboutNavTxt}
          </NavLink>
        </Nav.Link>
        <Nav.Link eventKey="2" className=" text-center my-auto mx-1">
          <NavLink
            className={({ isActive }) =>
              !isActive ? "inActiveColor" : "activeColor"
            }
            to="/contact"
          >
            {textString.contactNavTxt}
          </NavLink>
        </Nav.Link>
        <Nav.Link eventKey="3" className=" text-center my-auto mx-1">
          <NavLink
            className={({ isActive }) =>
              !isActive ? "inActiveColor" : "activeColor"
            }
            to="/help"
          >
            {textString.helpNavTxt}
          </NavLink>
        </Nav.Link>
        {isAuth ? (
          <Nav.Link eventKey="6" className=" text-center my-auto mx-1">
            <NavLink
              className={({ isActive }) =>
                !isActive ? "inActiveColor" : "activeColor"
              }
              to="/wallet"
            >
              {textString.walletNavTxt}
            </NavLink>
          </Nav.Link>
        ) : (
          <Nav.Link eventKey="4" className=" text-center my-auto mx-1">
            <NavLink
              className={({ isActive }) =>
                !isActive ? "inActiveColor" : "activeColor"
              }
              to="/login"
            >
              {textString.loginNavTxt}
            </NavLink>
          </Nav.Link>
        )}

        <Nav.Link eventKey="5" className=" text-center my-auto mx-1">
          <NavLink
            className={({ isActive }) =>
              !isActive ? "inActiveColor" : "activeColor"
            }
            to="/tips"
          >
            {textString.tipsNavTxt}
          </NavLink>
        </Nav.Link>
      </>
    );
  };
  return (
    <>
      <Navbar
        collapseOnSelect
        sticky="top"
        expand={"lg"}
        className="w-100 customColorTopBarBg"
      >
        <Container>
          <Navbar.Toggle
            className="customTogleBtn py-1"
            aria-controls={`offcanvasNavbar-expand-lg`}
          />
          <NavLink
            to="/"
            onClick={() => {
              const section = document.querySelector("#mainSection");
              section?.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest",
              });
            }}
            style={{
              marginRight: "0px",
              width: "auto",
              background: "transparent",
            }}
            className="navbar-brand d-flex nillbtn h-100 allCenter  py-1"
          >
            <img
              style={{ objectFit: "contain", height: "30px" }}
              className="logoImgResp"
              src={logoimg}
              alt="logo"
            />
          </NavLink>

          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                Offcanvas
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-center flex-grow-1">
                <CustNavs />
              </Nav>
              <div
                className="d-flex align-items-center justify-content-between flex-column flex-lg-row"
                style={{ flexGrow: 0.2 }}
              >
                <div className="d-flex align-items-center justify-content-center justify-content-lg-start flex-column flex-lg-row flex-grow-1">
                  <Nav.Link eventKey="8" className=" text-center my-auto mx-1">
                    <NavLink
                      className={({ isActive }) =>
                        !isActive
                          ? "iconButtonCustom inActiveColor"
                          : "iconButtonCustom activeColor"
                      }
                      to="/cart"
                    >
                      <AiOutlineShoppingCart />
                    </NavLink>
                  </Nav.Link>
                  <Nav.Link eventKey="9" className=" text-center my-auto mx-1">
                    <NavLink
                      className={({ isActive }) =>
                        !isActive
                          ? "iconButtonCustom inActiveColor"
                          : "iconButtonCustom activeColor"
                      }
                      to="/favorite"
                    >
                      <AiOutlineHeart />
                    </NavLink>
                  </Nav.Link>
                </div>
                <div className="d-flex align-items-center justify-content-center justify-content-lg-end flex-column flex-lg-row  flex-grow-1">
                  <button className="iconButtonCustom">
                    <BsTelephone />
                  </button>
                  <button className="iconButtonCustom">
                    <AiOutlineInstagram />
                  </button>
                  <button className="iconButtonCustom">
                    <FaSnapchat />
                  </button>
                </div>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}

export default TopBar;
