import React from "react";
import successIcon from "../assets/successpay.png";
import { textString } from "../assets/TextStrings";
import { useNavigate } from "react-router-dom";
function PaySuccessPage() {
  const navigate = useNavigate();
  return (
    <div className="container my-4 successPage">
      <img src={successIcon} alt="success" />
      <p>{textString.successDoneTxt}</p>
      <button onClick={() => navigate("/")}>{textString.okTxt}</button>
    </div>
  );
}

export default PaySuccessPage;
