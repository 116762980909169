import TopBar from "./Components/TopBar/TopBar";
import FileRoutesNav from "./Navigation/FileRoutesNav";
import FooterBar from "./Components/FooterBar/FooterBar";
import { useEffect, useState } from "react";
import logo from "./assets/logo.png";
import {
  checkIsUserExist,
  getDataWholeCollection,
  getMYServicesReq,
} from "./DataBase/databaseFunction";
import { useDispatch } from "react-redux";
import {
  setBatteryCompaniesData,
  setBatteryData,
  setClientCarsData,
  setFiltersDta,
  setMySupportServicesData,
  setOilCompaniesData,
  setOilsData,
  setSupportServicesData,
  setTireCompaniesData,
  setTireData,
} from "./store/projectSlice";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { setAuth, setCartItems, setFavoriteItems } from "./store/authSlice";
import { ToastContainer } from "react-toastify";

window.webengage.init("in~aa1316d7");

function App() {
  const [layoutChange, setlayoutChange] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    window.webengage.user.login("9SBOkLVMWvPX"); //9SBOkLVMWvPX is the unique user identifier being used here
    window.webengage.user.setAttribute("we_email", "john@doe.com");
    window.webengage.user.setAttribute("we_birth_date", "1986-08-19");
    const fetchCartAndFavorites = async () => {
      setlayoutChange(false);

      const asyncCartItem = await AsyncStorage.getItem("ac_Zurex_client_cart");
      const asyncFavoriteItem = await AsyncStorage.getItem(
        "ac_Zurex_client_favorite"
      );
      const userAddedCars = await AsyncStorage.getItem(
        "ac-zurex-client-web-cars"
      );

      if (asyncCartItem) {
        dispatch(setCartItems({ cartItems: JSON.parse(asyncCartItem) }));
      }
      if (asyncFavoriteItem) {
        dispatch(
          setFavoriteItems({ favoriteItems: JSON.parse(asyncFavoriteItem) })
        );
      }
      if (userAddedCars) {
        dispatch(setClientCarsData({ clientCarsData: userAddedCars }));
      }
      setlayoutChange(true);
    };
    const fetchAccountData = async () => {
      setlayoutChange(false);
      const phoneNumber = await AsyncStorage.getItem("ac_zurex_web_client");
      if (phoneNumber) {
        await checkIsUserExist(`${phoneNumber}`)
          .then(async (dat) => {
            dispatch(setAuth({ isAuth: dat }));
            const data = await getMYServicesReq(dat.userId);
            if (data?.length > 0) {
              dispatch(
                setMySupportServicesData({ mySupportServicesData: data })
              );
            }
            setlayoutChange(true);
          })
          .catch((e) => {
            setlayoutChange(true);
            console.log(e);
          });
      }
    };
    const getAllOtherData = async () => {
      const BatteryCompanies = await getDataWholeCollection("BatteryCompanies");
      dispatch(
        setBatteryCompaniesData({
          batteryCompaniesData: BatteryCompanies?.map((dat) => {
            return { ...dat, referance: "BatteryCompanies" };
          }),
        })
      );
      const filters = await getDataWholeCollection("Filters");
      dispatch(
        setFiltersDta({
          filtersData: filters?.map((dat) => {
            return { ...dat, referance: "Filters" };
          }),
        })
      );
      const OilCompanies = await getDataWholeCollection("OilCompanies");
      dispatch(
        setOilCompaniesData({
          oilCompaniesData: OilCompanies?.map((dat) => {
            return { ...dat, referance: "OilCompanies" };
          }),
        })
      );
      const Oils = await getDataWholeCollection("Oils");
      dispatch(
        setOilsData({
          oilsData: Oils?.map((dat) => {
            return { ...dat, referance: "Oils" };
          }),
        })
      );
      const TireCompanies = await getDataWholeCollection("TireCompanies");
      dispatch(
        setTireCompaniesData({
          tireCompaniesData: TireCompanies?.map((dat) => {
            return { ...dat, referance: "TireCompanies" };
          }),
        })
      );
      const Tyres = await getDataWholeCollection("Tyres");
      dispatch(
        setTireData({
          tireData: Tyres?.map((dat) => {
            return { ...dat, referance: "Tyres" };
          }),
        })
      );
      const btteries = await getDataWholeCollection("btteries");
      dispatch(
        setBatteryData({
          batteryData: btteries?.map((dat) => {
            return { ...dat, referance: "btteries" };
          }),
        })
      );
      const support = await getDataWholeCollection("SupportServices");
      dispatch(
        setSupportServicesData({
          supportServicesData: support?.map((dat) => {
            return { ...dat, referance: "SupportServices" };
          }),
        })
      );
    };
    fetchCartAndFavorites();
    fetchAccountData();
    getAllOtherData();
  }, [dispatch]);
  return (
    <>
      {layoutChange ? (
        <>
          <TopBar />
          <FileRoutesNav />
          <FooterBar />
        </>
      ) : (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ width: "100%", height: "100vh", background: "#ffffff" }}
        >
          <img
            style={{ width: "120px", objectFit: "contain" }}
            src={logo}
            alt="logo"
          />
        </div>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default App;
