import React, { useState } from "react";
import articleImg from "../assets/article2.png";
import { textString } from "../assets/TextStrings";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useDispatch, useSelector } from "react-redux";
import { setClientCarsData } from "../store/projectSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
function EnterCarDataPage() {
  const [carType, setcarType] = useState("");
  const [carCategory, setcarCategory] = useState("");
  const [carModal, setcarModal] = useState("");
  const [carPlate, setcarPlate] = useState("");
  const { clientCarsData } = useSelector((state) => state.project);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addCarData = async (e) => {
    e.preventDefault();
    const data = { carType, carModal, carCategory, carPlate };
    const dataToPust =
      clientCarsData?.length > 0
        ? [...clientCarsData, { ...data }]
        : [{ ...data }];
    await AsyncStorage.setItem(
      "ac-zurex-client-web-cars",
      JSON.stringify(dataToPust)
    );
    dispatch(setClientCarsData({ clientCarsData: dataToPust }));
    toast.success("Car added");
    navigate("/");
  };
  return (
    <>
      <div className="btn w-100 homeBtnMainDivWala mt-2">
        {textString.enterYourCarDataTxt}
      </div>
      <form onSubmit={addCarData} className="container my-5 enterCarData">
        <img src={articleImg} alt="enterData" />
        <input
          required
          minLength={5}
          value={carType}
          onChange={(e) => setcarType(e.target.value)}
          placeholder={textString.carTypeTxt}
        />
        <input
          required
          minLength={5}
          value={carCategory}
          onChange={(e) => setcarCategory(e.target.value)}
          placeholder={textString.carCategTxt}
        />
        <input
          required
          minLength={5}
          value={carModal}
          placeholder={textString.carModalTxt}
          onChange={(e) => setcarModal(e.target.value)}
        />
        <input
          required
          minLength={5}
          value={carPlate}
          placeholder={textString.plateNumTxtInp}
          onChange={(e) => setcarPlate(e.target.value)}
        />
        <button type="submit" className="lastBtn">
          {textString.addCarsTxt}
        </button>
      </form>
    </>
  );
}

export default EnterCarDataPage;
