import React from "react";
import "./FooterBar.scss";
import { textString } from "../../assets/TextStrings";
import paymentimg from "../../assets/payments.png.png";
import belowpaymentimg from "../../assets/belowpayment.png";
import { AiOutlineInstagram } from "react-icons/ai";
import { FaSnapchat, FaFacebookF, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import locationpng from "../../assets/Location.png";
import Messagepng from "../../assets/Message.png";
import callpng from "../../assets/Call.png";
import { useDispatch, useSelector } from "react-redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { setAuth } from "../../store/authSlice";
function FooterBar() {
  const { isAuth } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const logoutFunc = async () => {
    await AsyncStorage.removeItem("ac_zurex_web_client");
    dispatch(setAuth({ isAuth: null }));
  };
  return (
    <div className="wholeSection2 w-100">
      <div style={{ width: "95%" }} className="widthissue marginIssue row">
        <div className="col-12 col-md-5 col-lg-3 mb-4 d-flex align-items-start justify-content-between flex-column manageHeightIssue">
          <p className="apptxtStyle mb-0">{textString.footerMainTxt}</p>
          <p className="apptxtStyle">{textString.footerMainTxt2}</p>
          <img
            src={paymentimg}
            style={{
              height: "1.875rem",
              objectFit: "contain",
              width: "100%",
              maxWidth: "340px",
            }}
            className="mt-2"
            alt="payments"
          />
          <div className="btnContainerSocial my-3">
            <button>
              <FaFacebookF />
            </button>
            <button>
              <FaTwitter />
            </button>
            <button>
              <AiOutlineInstagram />
            </button>
            <button>
              <FaSnapchat />
            </button>
          </div>
          <div className="w-100 d-flex align-items-center justify-content-start flex-row">
            <img
              src={belowpaymentimg}
              style={{
                height: "1.5rem",
                objectFit: "contain",
                maxWidth: "80px",
              }}
              alt="payments"
            />
            <span className="taxNumberTxt">
              {textString.taxtHeading} : <b>300054451200003</b>
            </span>
          </div>
        </div>
        <div className="col-12 col-md-7 col-lg-9 mb-4 showsamllWidth">
          <div className="row p-0">
            <div className="col-12 col-md-6 col-lg-2">
              <p className="headingSpan">{textString.anglesTxt}</p>
              <Link to="/" className="mycustomNavFootr">
                {textString.productbtmTxt}
              </Link>
              <Link to="/" className="mycustomNavFootr">
                {textString.mReqProductTxt}
              </Link>
              <Link to="/contact" className="mycustomNavFootr">
                {textString.contactNavTxt}
              </Link>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <p className="headingSpan">{textString.informationTxt}</p>
              <Link to="/help" className="mycustomNavFootr">
                {textString.faqTxt}
              </Link>
              <Link to="/about" className="mycustomNavFootr">
                {textString.aboutAppTxt}
              </Link>
              <Link className="mycustomNavFootr">{textString.termCondApp}</Link>
              <Link className="mycustomNavFootr">
                {textString.wholeSaleOrderTxt}
              </Link>
            </div>
            <div className="col-12 col-md-6 col-lg-2">
              <p className="headingSpan">{textString.accountTxt}</p>
              {isAuth ? (
                <p
                  onClick={logoutFunc}
                  style={{ cursor: "pointer" }}
                  className="mycustomNavFootr"
                >
                  Logout
                </p>
              ) : (
                <Link to="/login" className="mycustomNavFootr">
                  {textString.loginNavTxt}
                </Link>
              )}

              <Link className="mycustomNavFootr">{textString.orderTxt}</Link>
            </div>
            <div className="col-12 col-md-6 col-lg-5">
              <p className="headingSpan">{textString.contactNavTxt}</p>
              <div className="footerContctUsItem">
                <img src={locationpng} alt="location" />
                <span>{textString.contactUsLocation}</span>
              </div>
              <div className="footerContctUsItem">
                <img src={callpng} alt="location" />
                <span>9230002574</span>
              </div>
              <div className="footerContctUsItem">
                <img src={Messagepng} alt="location" />
                <span>info@ac-zurex.sa</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyrightdivtxt">{textString.copyrightTxt}</div>
    </div>
  );
}

export default FooterBar;
