import React, { useState } from "react";
import { textString } from "../assets/TextStrings";
import logo from "../assets/logo.png";
import OtpInput from "react-otp-input";
import {
  checkIsUserExist,
  nonAuth,
  postData,
} from "../DataBase/databaseFunction";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../store/authSlice";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuth } = useSelector((state) => state.auth);
  const [loginLayout, setloginLayout] = useState(true);
  const [otp, setOtp] = useState("");
  const phoneCode = "+966";
  const [phoneNumber, setPhoneNumber] = useState("");
  const onCaptchVerify = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new nonAuth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            console.log("done");
          },
          "expired-callback": () => {},
        }
      );
    }
  };
  const onSignup = () => {
    onCaptchVerify();
    const appVerifier = window.recaptchaVerifier;
    const formatPh = `${phoneCode}${phoneNumber}`;
    nonAuth()
      .signInWithPhoneNumber(formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        toast.success("OTP sended successfully!");
        setloginLayout(!loginLayout);
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  const onOTPVerify = () => {
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        //alert(`${res.user.phoneNumber}, ${res.user.uid}`);
        await checkIsUserExist(`${res.user.phoneNumber}`).then(async (data) => {
          if (data) {
            toast.success("Login Successfull");
            dispatch(setAuth({ isAuth: data }));
            await AsyncStorage.setItem(
              "ac_zurex_web_client",
              `${data.phoneNumber}`
            );
          } else {
            const dataToPost = {
              name: "client default username",
              phoneNumber: `${phoneCode}${phoneNumber}`,
              userImage: "",
              balance: 0,
              userEmail: "client default email",
            };
            await postData("user", { ...dataToPost }).then(async (dat) => {
              await AsyncStorage.setItem(
                "ac_zurex_web_client",
                `${dataToPost.phoneNumber}`
              );
              dispatch(setAuth({ isAuth: dataToPost }));
              toast.success("Account Created and Loged in Successfull");
            });
          }
        });
      })
      .catch((err) => {
        alert(err);
      });
  };
  if (isAuth && isAuth?.phoneNumber) {
    navigate("/wallet");
  }
  return (
    <div className="container my-5 loginPage">
      <img src={logo} className="topLogoPagediv" alt="logo" />
      <div className="legendContainer">
        <div id="recaptcha-container"></div>
        <p className="legendTitle">
          {loginLayout ? textString.loginNavTxt : textString.codeActiveTxt}
        </p>
        <p className="welcomeTxt">{textString.welcomeTxt}</p>
        <p className="loginFirstTxt">
          {loginLayout ? textString.loginFirstTxt : textString.activCodeTxt}
        </p>
        {loginLayout ? (
          <>
            <div className="loginInputDiv">
              <input
                placeholder="59xxxxxxx"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <div className="sideContainer">{phoneCode}</div>
            </div>
            <div className="loginInputDiv">
              <input placeholder={textString.countrySelctTxt} />
              <div className="sideContainer"></div>
            </div>
            <button onClick={onSignup}>{textString.sendBtnTxt}</button>
          </>
        ) : (
          <>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              inputType="number"
              renderSeparator={<div style={{ marginLeft: "10px" }} />}
              inputStyle={{
                width: "4rem",
                height: "4rem",
                borderRadius: "10px",
                border: "1px solid #003978",
                outline: "0px",
                fontSize: "1.7rem",
                color: "#003978",
              }}
              containerStyle={{ marginBottom: "3rem", marginTop: "2.5rem" }}
              renderInput={(props) => <input {...props} />}
            />
            <button onClick={onOTPVerify}>{textString.signInTxt}</button>
          </>
        )}
        {/* <button onClick={() => setloginLayout(!loginLayout)}>
          {loginLayout ? textString.signInTxt : textString.sendBtnTxt}
        </button> */}
      </div>
    </div>
  );
}

export default LoginPage;
