import React from "react";
import { textString } from "../assets/TextStrings";
import logo from "../assets/logo.png";
function AboutPage() {
  const aboutArray = textString.aboutUsArray;
  return (
    <div className="container aboutPage mt-2">
      <img src={logo} className="topLogoPagediv" alt="logo" />
      {aboutArray &&
        aboutArray?.map((dat) => (
          <>
            <h2 className="reasonHeading">{dat?.title}</h2>
            <p className="reasonPara">{dat.subHeading}</p>
          </>
        ))}
    </div>
  );
}

export default AboutPage;
