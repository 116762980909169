import React, { useState } from "react";
import logo from "../assets/logo.png";
import { textString } from "../assets/TextStrings";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import closeIcon from "../assets/ShieldFail.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { postData } from "../DataBase/databaseFunction";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { setCartItems } from "../store/authSlice";

function AddressPage() {
  const dispatch = useDispatch();
  const [dateTimeModal, setdateTimeModal] = useState(false);
  const [timeSelectedValue, settimeSelectedValue] = useState("");
  const { filtersData, oilsData, tireData, batteryData } = useSelector(
    (state) => state.project
  );
  const { orderProcessName, curentOrderProductData } = useSelector(
    (state) => state.orderProcess
  );
  const [dateValue, setdateValue] = useState(new Date());
  const [isSelectedValue, setisSelectedValue] = useState(false);
  const { isAuth, cartItems } = useSelector((state) => state.auth);
  const [cityName, setcityName] = useState("");
  const [locationCoordinates, setlocationCoordinates] = useState({
    latitude: 0,
    longitude: 0,
  });
  const navigate = useNavigate();

  const filteredDataFun = (id, referance) => {
    const finalizingDataType =
      referance === "Filters"
        ? filtersData
        : referance === "Tyres"
        ? tireData
        : referance === "btteries"
        ? batteryData
        : referance === "Oils"
        ? oilsData
        : [];
    const finalData = finalizingDataType?.find((dat) => dat.id === id);
    return finalData;
  };
  function calculatePrice() {
    let price = 0;
    cartItems?.map((dat, index) => {
      const data = filteredDataFun(dat.id, dat.referance);
      const newPriceData =
        parseFloat(data.originalPrice) * parseInt(dat.quantity);
      price = price + newPriceData;
      return null;
    });
    return price;
  }
  const [addressInputNew, setaddressInputNew] = useState("");
  const timeArray = [
    { id: 0, time: "10:30 am" },
    { id: 1, time: "11:30 am" },
    { id: 2, time: "12:30 am" },
    { id: 3, time: "01:30 pm" },
    { id: 4, time: "02:30 pm" },
    { id: 5, time: "03:30 pm" },
    { id: 6, time: "04:00 pm" },
    { id: 7, time: "04:30 pm" },
    { id: 8, time: "05:00 pm" },
    { id: 9, time: "05:30 pm" },
  ];

  if (!isAuth) {
    navigate("/wallet");
  }
  const handleChange = (address) => {
    setaddressInputNew(address);
  };

  const handleSelect = (address) => {
    setaddressInputNew(address);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setlocationCoordinates({ latitude: latLng.lat, longitude: latLng.lng });
      })
      .catch((error) => console.error("Error", error));
  };
  const postOrderOnDd = async (e) => {
    e.preventDefault();
    if (
      cartItems?.length > 0 ||
      Object.keys(curentOrderProductData).length > 0
    ) {
      if (dateValue !== null && timeSelectedValue !== "" && isSelectedValue) {
        if (orderProcessName === "support") {
          if (Object.keys(curentOrderProductData).length > 0) {
            const dataToPost = {
              products: curentOrderProductData,
              OrderedByUserId: isAuth.userId,
              appointment: {
                date: new Date(dateValue).toDateString(),
                time: timeSelectedValue,
              },
              deliveryInfo: {
                cityName: cityName,
                locationCoordinates: locationCoordinates,
                locationName: addressInputNew,
              },
              orderPrice: curentOrderProductData?.originalPrice,
              orderStatus: "pending",
              productType: "web",
              selectedCar: {
                carName: "BMW",
                category: "Seventh Category",
                imglink:
                  "https://firebasestorage.googleapis.com/v0/b/aczurex-d4b61.appspot.com/o/pngwing.com.png?alt=media&token=3236869c-480f-4c7c-82c2-babb54f715a2",
                numberPlate: "7.8339 - 90",
              },
            };

            await postData("supportOrders", {
              ...dataToPost,
            }).then(async () => {
              toast.success("Services order posted successfully");
              navigate("/");
            });
          } else {
            toast.error("Unexpected error occured");
          }
        } else {
          const dataToPost = {
            products: cartItems,
            OrderedByUserId: isAuth.userId,
            appointment: {
              date: new Date(dateValue).toDateString(),
              time: timeSelectedValue,
            },
            deliveryInfo: {
              cityName: cityName,
              locationCoordinates: locationCoordinates,
              locationName: addressInputNew,
            },
            orderPrice: calculatePrice(),
            orderStatus: "pending",
            productType: "web",
            selectedCar: {
              carName: "BMW",
              category: "Seventh Category",
              imglink:
                "https://firebasestorage.googleapis.com/v0/b/aczurex-d4b61.appspot.com/o/pngwing.com.png?alt=media&token=3236869c-480f-4c7c-82c2-babb54f715a2",
              numberPlate: "7.8339 - 90",
            },
          };

          await postData("orders", {
            ...dataToPost,
          }).then(async () => {
            await AsyncStorage.setItem(
              "ac_Zurex_client_cart",
              JSON.stringify([])
            );
            dispatch(setCartItems({ cartItems: [] }));
            toast.success("order posted successfully");
            navigate("/");
          });
        }
      } else {
        toast.error("Please select the appointment date and time slot");
      }
    } else {
      toast.error("Cart is empty");
    }
  };
  return (
    <div className="container paymentPage addressPage loginPage my-4">
      <img src={logo} className="topLogoPagediv" alt="logo" />
      <form onSubmit={postOrderOnDd} className="legendContainer py-4">
        <div className="loginInputDivNew mb-4">
          <PlacesAutocomplete
            value={addressInputNew}
            onChange={handleChange}
            onSelect={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <input
                  {...getInputProps({
                    placeholder: textString.yourAddress,
                    className: "location-search-input",
                  })}
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    // inline style for demonstration purpose
                    /* const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" }; */

                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          //style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </div>

        <p className="legendTitle">{textString.chooseShippingWay}</p>

        <div className="loginInputDiv mb-4">
          <input
            value={cityName}
            onChange={(e) => setcityName(e.target.value)}
            required
            minLength={10}
            placeholder={textString.strretTownAddress}
          />
          <div className="sideContainer"></div>
        </div>

        <div
          className="loginInputDiv mb-4"
          onClick={() => setdateTimeModal(!dateTimeModal)}
        >
          <span style={{ color: isSelectedValue ? "black" : "#8e8e8e" }}>
            {isSelectedValue
              ? new Date(dateValue).toDateString()
              : textString.selectDeliveryDate}
          </span>
          <div className="sideContainer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 448 512"
              fill="#8e8e8e"
            >
              <path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z" />
            </svg>
          </div>
        </div>
        <div
          className="loginInputDiv mb-4 py-4 px-2"
          style={{ minHeight: "170px", height: "auto", overflowY: "auto" }}
        >
          <div
            className="d-flex flex-row align-items-start justify-content-start row pt-2"
            style={{ height: "100%", width: "100%", overflowY: "auto" }}
          >
            {timeArray?.map((dat, index) => (
              <div
                key={index}
                onClick={() => settimeSelectedValue(dat.time)}
                className={`col timeContainer ${
                  timeSelectedValue === dat.time ? "borderView" : ""
                }`}
              >
                {dat.time}
              </div>
            ))}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center w-100">
          <button onClick={() => navigate(-1)}>{textString.backBtnTxt}</button>
          <button type="submit">{textString.nextbtnTxt}</button>
        </div>
      </form>
      <Modal
        show={dateTimeModal}
        onHide={() => setdateTimeModal(!dateTimeModal)}
      >
        <Modal.Header>
          <button
            onClick={() => setdateTimeModal(!dateTimeModal)}
            className="custbutton"
          >
            <img src={closeIcon} alt="close" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="modalBtnContainers">
            <img src={logo} className="topLogoPagediv" alt="logo" />
            <p
              style={{
                fontSize: "1.813rem",
                lineHeight: "2.5rem",
                fontWeight: "bold",
                textAlign: "center",
                color: "#003978",
              }}
              className="paraModalWlal mb-4"
            >
              {textString.selectDeliveryDate}
            </p>
            <div className="w-100 d-flex align-items-center justify-content-center">
              <DatePicker
                selected={dateValue}
                onChange={(text) => {
                  setdateValue(text);
                  setdateTimeModal(!dateTimeModal);
                  setisSelectedValue(true);
                }}
                inline
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AddressPage;
